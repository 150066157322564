<script setup lang="ts">
import type { target, Options, PartialFormatter } from 'nouislider';
import NoUiSlider from 'nouislider';

export interface Props {
    connect?: Options['connect'];
    hover?: boolean;
    modelValue: number | [number, number];
    range: Options['range'];
    snap?: Options['snap'];
    step?: Options['step'];
    // For some reason, in production this only accepts an array
    tooltips?: (boolean | PartialFormatter)[];
}

const props = defineProps<Props>();
const emit = defineEmits<{
    'update:modelValue': [values: number | [number, number]];
}>();

const refSlider = ref<target | null>(null);

const createSlider = () => {
    if (!refSlider.value) return;

    // Create the slider
    NoUiSlider.create(refSlider.value, {
        connect: props.connect,
        range: props.range,
        snap: props.snap,
        start: props.modelValue,
        step: props.step,
        tooltips: props.tooltips,
        handleAttributes:
            typeof props.modelValue === 'number'
                ? [{ 'aria-label': 'handle' }]
                : [
                      { 'aria-label': 'lower handle' },
                      { 'aria-label': 'upper handle' },
                  ],
    });
};

const destroySlider = () => {
    if (!refSlider.value) return;
    if (!refSlider.value.noUiSlider) return;

    refSlider.value.noUiSlider.destroy();
};

const setSliderEvents = () => {
    if (!refSlider.value) return;
    if (!refSlider.value.noUiSlider) return;

    // Change event is lazy and does not run on .set()
    refSlider.value.noUiSlider.on('change', () => {
        if (!refSlider.value?.noUiSlider) return;

        const decodedValues = refSlider.value?.noUiSlider?.get(true);

        // If using tuple
        if (
            Array.isArray(decodedValues) &&
            typeof decodedValues[0] === 'number' &&
            typeof decodedValues[1] === 'number'
        ) {
            emit('update:modelValue', [
                // Round due to javascript internal floating point precision
                Math.round(decodedValues[0]),
                Math.round(decodedValues[1]),
            ]);
        }

        // If using single number
        if (typeof decodedValues === 'number') {
            emit('update:modelValue', Math.round(decodedValues));
        }
    });
};

// Update range if it changes
watch(
    () => props.range,
    (newRange) => {
        if (!refSlider.value?.noUiSlider) return;

        refSlider.value.noUiSlider.updateOptions(
            {
                range: newRange,
            },
            false
        );
    }
);

// Update slider object if v-model has upstream changes
watch(
    () => props.modelValue,
    (newValue) => {
        if (!refSlider.value?.noUiSlider) return;

        // Get raw numbers
        const currentValue = refSlider.value.noUiSlider.get(true);

        // If using tuple
        if (
            Array.isArray(currentValue) &&
            Array.isArray(newValue) &&
            typeof currentValue[0] === 'number' &&
            typeof currentValue[1] === 'number'
        ) {
            // Round due to javascript internal floating point precision
            const hasChangeLeft = Math.round(currentValue[0]) !== newValue[0];
            const hasChangeRight = Math.round(currentValue[1]) !== newValue[1];

            if (hasChangeLeft || hasChangeRight) {
                refSlider.value.noUiSlider.set([newValue[0], newValue[1]]);
            }
        }

        // if using single number
        if (typeof currentValue === 'number' && typeof newValue === 'number') {
            // Round due to javascript internal floating point precision
            const hasChange = Math.round(currentValue) !== newValue;

            if (hasChange) {
                refSlider.value.noUiSlider.set(newValue);
            }
        }
    }
);

onMounted(() => {
    createSlider();
    setSliderEvents();
});

onBeforeUnmount(() => {
    destroySlider();
});
</script>

<template>
    <div class="range-slider" :class="{ hover }">
        <div id="slider" ref="refSlider"></div>
    </div>
</template>

<style>
@import url('nouislider/dist/nouislider.min.css');
</style>

<style lang="postcss" scoped>
.range-slider {
    /* Override NoUiSlider styles */
    /* stylelint-disable selector-class-pattern */
    & :deep(.noUi-target) {
        background: var(--color-light-border);
        border: initial;
        border-radius: 1rem;
        box-shadow: initial;
        height: 6px;

        & .noUi-handle {
            cursor: pointer;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 100%;
            box-shadow: var(--shadow-3);
            right: -12px;
            top: -9px; /* This value is inversely proportional to .noUi-target height */
            font-weight: var(--font-weight-normal);

            &::before,
            &::after {
                display: none;
            }

            &:focus {
                box-shadow: 0 0 1px 2px rgba(52 152 219 / 36%);
            }

            & .noUi-tooltip {
                border: initial;
                background: initial;
                font-size: 14px;
                color: var(
                    --range-slider-tooltip-color,
                    var(--color-font-dark)
                );
            }
        }

        & .noUi-connect {
            background: var(--color-accent);
        }
    }

    &.hover {
        & :deep(.noUi-target) {
            & .noUi-handle {
                & .noUi-tooltip {
                    display: none;
                    color: var(--color-text-on-accent);
                    background-color: var(--color-accent);
                    border-radius: 5px;
                    padding: 2px 5px;
                    margin-bottom: 6px;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        transform: translate(-50%);
                        height: 0;
                        width: 0;
                        border: 5px solid transparent;
                        border-top-color: var(--color-accent);
                    }
                }
            }

            & .noUi-handle:hover,
            & .noUi-handle:focus {
                & .noUi-tooltip {
                    display: block;
                }
            }
        }
    }
    /* stylelint-enable selector-class-pattern */
}
</style>
